import React from 'react'
import { IoIosArrowBack} from "react-icons/io";

const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div onClick={onClick} className='arrow_key text-3xl font-semibold absolute top-1/2  left-2 sm:left-0 -translate-y-1/2 cursor-pointer z-10'>
            <IoIosArrowBack />
        </div>
    );
};

export default CustomPrevArrow