import React from 'react'
import { IoIosArrowForward } from "react-icons/io";


const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="text-3xl font-semibold absolute top-1/2 right-2 sm:right-0 -translate-y-1/2 cursor-pointer z-10 arrow_key" onClick={onClick}>
            <IoIosArrowForward />
        </div>
    );
};

export default CustomNextArrow