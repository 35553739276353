import axios from "axios";
import { productByCategorySlugFail, productByCategorySlugRequest, productByCategorySlugSuccess } from "../slices/productsByCategorySlugSlice";
import { ProductBySlugRequest, ProductBySlugSuccess, ProductBySlugFail } from "../slices/ProductBySlug";
import { getQuoteRequest, getQuoteSuccess, getQuoteFailure } from "../slices/getQuote";
import { backendUrl } from "../config/config";
import { toast } from "react-toastify";



export const getProductsByCategorySlug = slug => async (dispatch) => {
    try {
        dispatch(productByCategorySlugRequest())
        const { data } = await axios.get(backendUrl + `get_products_by_category_slug/${slug}`)
        dispatch(productByCategorySlugSuccess(data))
    } catch (error) {
        dispatch(productByCategorySlugFail(error.response.data.message));
    }
}


export const getProductBySlug = slug => async (dispatch) => {
    try {
        dispatch(ProductBySlugRequest())
        const { data } = await axios.get(backendUrl + `get_product_by_slug/${slug}`)
        dispatch(ProductBySlugSuccess(data))
    } catch (error) {
        dispatch(ProductBySlugFail(error.response.data.message));
    }
}

export const getQuote = (formData) => async (dispatch) => {
    try {
        dispatch(getQuoteRequest())
        const { data } = await axios.post(backendUrl + `get_quote`, formData)
        if(data.email_send){
            toast.success('Your Quote sent Successfully');
        }else{
            toast.error('There is an error sending Quote , Please try agail Later');
        }
        dispatch(getQuoteSuccess(data))
    } catch (error) {
        dispatch(getQuoteFailure(error.response.data.message));
    }
}