import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import productsByCategorySlugSlice from './slices/productsByCategorySlugSlice';
import productBySlug from './slices/ProductBySlug';
import getQuote from './slices/getQuote';
import getBlogCategory from './slices/GetBlogCategory';
import getBlogByslug from './slices/GetBlogBySlug';
const reducer = combineReducers({
    productsByCategorySlugState: productsByCategorySlugSlice,
    productBySlugState: productBySlug,
    getQuoteState: getQuote,
    getBlogCategoryState: getBlogCategory,
    getBlogByslugState: getBlogByslug,
})

const store = configureStore({
    reducer,
    middleware: [thunk]
})

export default store;