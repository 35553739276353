import { createSlice } from "@reduxjs/toolkit";

const getQuoteSlice = createSlice({
    name: 'getQuote',
    initialState: {
        loading: false,
        mail_sent: false,
    },
    reducers: {
        getQuoteRequest: (state) => {
            return {
                loading: true,
            }
        },
        getQuoteSuccess: (state, action) => {
            return {
                loading: false,
                mail_sent: action.payload.mail_sent,
            }
        },
        getQuoteFailure: (state, action) => {
            return {
                loading: false,
                error: action.payload
            }
        },
    },
});

const { actions, reducer } = getQuoteSlice;

export const { getQuoteRequest, getQuoteSuccess, getQuoteFailure } = actions;

export default reducer;