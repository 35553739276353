import React, { useState } from 'react'
import { Fragment } from 'react'
import Header from '../Header'
import product_img from '../../assets/images/product_images/1.png';
import { Link, useParams } from 'react-router-dom';
import Footer from '../Footer';
import EnqiuryPopup from '../EnqiuryPopup';
import Wave from '../Wave';
import { useDispatch, useSelector } from 'react-redux';
import { getProductBySlug } from '../../actions/productActions';
import { useEffect } from 'react';
import Loader from '../Loader';
import { backendImageUrl } from '../../config/config';
import ProductDescription from './ProductDescription';

const Product = () => {

    const [modalIsOpen, setModalIsOpen] = useState(false);


    const openModal = () => {
        setModalIsOpen(true);
        document.body.classList.add('enquiry_body');
    };

    const closeModal = () => {
        document.body.classList.remove('enquiry_body');
        setModalIsOpen(false);
    };


    const { slug } = useParams();
    const dispatch = useDispatch();
    const { product } = useSelector((state) => state.productBySlugState);

    useEffect(() => {
        dispatch(getProductBySlug(slug));
    }, [])


    return (
        <Fragment>
            <Header />
            <div  className='bg-[#f79324] mt-6 py-4 px-3 md:px-5 uppercase text-black font-semibold text-center text-base sm:text-lg md:text-xl'>
                SCOPE OF SUPPLY
            </div>
            {
                product ?
                    product.length == 0 ?
                        <h4 className=' text-2xl font-medium text-center'>Product Not Available</h4>
                        :
                        <div className='container mx-auto px-5 py-10 md:py-20'>

                            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2'>
                                <div>
                                    <img data-aos="fade-up" src={backendImageUrl + product.image} alt="product image" className='mx-auto w-[150px] sm:w-[200px] md:w-[250px] lg:w-[300px] h-[150px] sm:h-[200px] md:h-[250px] lg:h-[300px] object-contain' />
                                </div>
                                <div>
                                    <div  data-aos="fade-up" className=' text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium text-[#0c3646] underline underline-offset-8 transition-all'>{product.name}</div>
                                    <div  data-aos="fade-up" className='pt-8 flex flex-col gap-y-1'>
                                        <ProductDescription description={product && product.description} />
                                    </div>
                                    <div className='flex mt-5 md:mt-10 gap-5 md:gap-8' data-aos="fade-up">
                                        <Link  to={`/category/${product.category == 1 ? "marine-spares" : "deck-spares"}`} className='cursor-pointer  bg-[#f79324] hover:text-white transition-all mt-6 0c3646 py-2 md:py-4 px-4 md:px-8 text-[13px] sm:text-[15px] md:text-[18px] uppercase skew-x-[-20deg] book_taxi_inverse flex items-center'><span className='skew-x-[20deg] inline-block '>Back to Catalog</span></Link>
                                        <span  onClick={openModal} className='cursor-pointer  bg-[#0c3646] mt-6 text-white py-4 px-8 text-[13px] sm:text-[15px] md:text-[18px] uppercase skew-x-[-20deg] book_taxi flex items-center'><span className='skew-x-[20deg] inline-block '>Get a Quote</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    : <Loader />
            }
            <Footer />
            <Wave />
            <EnqiuryPopup isOpen={modalIsOpen} onRequestClose={closeModal} />
        </Fragment>
    )
}

export default Product