import React from 'react'
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { getQuote } from '../actions/productActions';
import validator from 'validator';
import { useEffect } from 'react';

const EnqiuryPopup = ({ isOpen, onRequestClose }) => {

    const dispatch = useDispatch();
    const [modelIsOpen, setModelIsOpen] = useState(false)
    const { loading, mail_sent } = useSelector((state) => state.getQuoteState);
    const [formData, setFormData] = useState({ name: '', email: '', company: '', file: '', message: '' });
    const [errors, setErrors] = useState({});


    useEffect(() => {
        if (!loading) {
            onRequestClose();
        }
    }, [loading])


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {

        e.preventDefault();

        // Validate the form fields
        const { name, email, company, file, message } = formData;
        const newErrors = {};

        if (validator.isEmpty(name)) {
            newErrors.name = 'Name is required';
        }

        if (!validator.isEmail(email)) {
            newErrors.email = 'Invalid email format';
        }

        if (validator.isEmpty(company)) {
            newErrors.company = 'Comapny is required';
        }
        if (validator.isEmpty(file)) {
            newErrors.file = 'File is required';
        }
        if (validator.isEmpty(message)) {
            newErrors.message = 'Message is required';
        }

        if (Object.keys(newErrors).length === 0) {
            setErrors({});
            // Form is valid, you can submit it or perform other actions here
            dispatch(getQuote(formData));
        } else {
            // Form is invalid, update the state with errors
            setErrors(newErrors);
        }

    };


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Example Modal"
        >
            <div className=' text-center text-3xl font-semibold'>GET A QUOTE</div>
            <form onSubmit={handleSubmit}>
                <div className='px-2 sm:px-5 md:px-10 py-3 sm:py-4 md:py-5'>
                    <div className='flex flex-col sm:flex-row flex-wrap mt-4 gap-x-10 gap-y-5'>
                        <div className='flex-1'>
                            <input type="text" placeholder='Name' name='name' onChange={handleChange} className=' border-gray-500 border w-full px-4 py-3' />
                            {errors.name && <p className="mt-2 px-2 text-white bg-red-500 py-1">{errors.name}</p>}
                        </div>
                        <div className='flex-1'>
                            <input type="text" placeholder='Email' name='email' onChange={handleChange} className=' border-gray-500 border w-full px-4 py-3' />
                            {errors.email && <p className="mt-2 px-2 text-white bg-red-500 py-1">{errors.email}</p>}
                        </div>
                    </div>
                </div>
                <div className='px-2 sm:px-5 md:px-10 py-3 sm:py-4 md:py-5'>
                    <input type="text" placeholder='Company' name='company' onChange={handleChange} className='border-gray-500 border w-full px-4 py-3' />
                    {errors.company && <p className="mt-2 px-2 text-white bg-red-500 py-1">{errors.company}</p>}
                </div>
                <div className='px-2 sm:px-5 md:px-10 py-3 sm:py-4 md:py-5'>
                    <input type="file" placeholder='Company' name='file' onChange={handleChange} className='border-gray-500 border w-full px-4 py-3' />
                    {errors.file && <p className="mt-2 px-2 text-white bg-red-500 py-1">{errors.file}</p>}
                </div>
                <div className='px-2 sm:px-5 md:px-10 py-3 sm:py-4 md:py-5'>
                    <textarea placeholder="Message (min 100 words)" name="message" onChange={handleChange} id="" cols="30" rows="10" className='border-gray-500 border w-full px-4 py-3'></textarea>
                    {errors.message && <p className="mt-2 px-2 text-white bg-red-500 py-1">{errors.message}</p>}
                </div>
                <div className='px-2 sm:px-5 md:px-10 py-3 sm:py-4 md:py-5'>
                    <button className={`bg-[#007ac2] text-white w-full px-4 py-3 ${loading && 'opacity-50'}`} type='submit' disabled={loading}>Send</button>
                </div>
            </form>

            <span onClick={onRequestClose} className='absolute top-2 right-2 text-[#bc2128] border border-[#bc2128] bg-white p-1 rounded-full cursor-pointer'>
                <FaTimes size={22} />
            </span>
        </Modal>
    )
}

export default EnqiuryPopup