import React, { useState } from 'react'
import logo from "../assets/images/marine_logo.jpg";
import { AiOutlineMail } from 'react-icons/ai';
import { BsTelephone } from 'react-icons/bs';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiFillCloseCircle } from 'react-icons/ai';
import NavList from './NavList';
import Modal from 'react-modal';
import EnqiuryPopup from './EnqiuryPopup';
import { Link } from 'react-router-dom';

const Header = () => {

    const [openNav, setOpenNav] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false);

    function openNavBat() {
        setOpenNav(true);
    }
    function closeNavBar() {
        setOpenNav(false);
    }



    const openModal = () => {
        setModalIsOpen(true);
        document.body.classList.add('enquiry_body');
    };

    const closeModal = () => {
        document.body.classList.remove('enquiry_body');
        setModalIsOpen(false);
    };
    return (
        <div className='relative' >
            {/* <div className='flex flex-nowrap sm:flex-wrap lg:flex-wrap items-center gap-x-3 sm:gap-x-5 gap-y-3 lg:gap-x-10 my-2 justify-between'>
                <img src={logo} alt="logo" className='w-[160px] cursor-pointer sm:w-[250px] md:w-[300px] lg:w-[400px] h-[auto]' />
                <div className='hidden sm:hidden md:flex gap-x-4 items-center'>
                    <span className='relative w-[45px] h-[45px] bg-[#f2f2f2] rounded-full'><AiOutlineMail className='text-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' /></span>
                    <span>Email Address:
                        <br />
                        <a href="mailto:sales@docksidemarine.com" className='font-semibold text-[18px]'>sales@docksidemarine.com</a>
                    </span>
                </div>
                <div className='flex gap-x-4 items-center'>
                    <span className='relative w-[35px] sm:w-[45px] h-[35px] sm:h-[45px] bg-[#f2f2f2] rounded-full'><BsTelephone className='text-[17px] md:text-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' /></span>

                    <span className='text-[14px] md:text-[16px]'>Phone Number:
                        <br />
                        <a href="tel:+971 42723260" className='font-semibold text-[15px] md:text-[18px]'>8825872238</a>
                    </span>
                </div>
                <span onClick={openModal} className='cursor-pointer hidden  md:inline-block bg-[#0c3646] mt-6 text-white py-4 px-8 text-[16px] sm:text-[17px] md:text-[18px] uppercase skew-x-[-20deg] book_taxi'><span className='skew-x-[20deg] inline-block '>Get a Quote</span></span>
            </div> */}
            <div className='container mx-auto py-3  md;py-4  px-5'>
                <div className=' flex flex-wrap md:flex-nowrap justify-center py-4 md:py-6 font-medium gap-x-20 gap-y-4'>
                    <div>
                    <span className=' opacity-60'>Contact:</span><a href="tel:+971 42723260" className='pl-2 font-semibold text-[15px] md:text-[18px]  underline-offset-2 underline'>+971 42723260</a>
                    </div>
                    <div>
                        <span className=' opacity-60'>E-mail:</span><a href="mailto:info@docksidemarines.com" className='pl-2 font-semibold text-[15px] md:text-[18px] underline'>info@docksidemarines.com</a>
                    </div>
                </div>
            </div>
            <div className='px-4 sm:px-6 md:px-8 lg:pl-16  md:py-5 bg-[#0c3646]'>
                <div className=''>
                    <div className='flex justify-between items-center'>
                        <Link to='/' className=' inline-block relative w-[100px] sm:w-[150px] md:w-[200px] lg:[250px]'>
                            <img src={logo} alt="logo" className='logo_parent py-3 px-2 sm:px-3 md:px-4.5 sm:py-2 md:py-4 lg:py-10' />
                        </Link>
                        <div>
                            <NavList ul_class="font-semibold text-[13px] text-white hidden md:hidden lg:flex gap-x-3 sm:gap-x-8 md:gap-x-10 lg:gap-x-15 ml-6 uppercase" li_class="relative" />
                        </div>
                        <span onClick={openModal} className='book_taxi_inverse cursor-pointer  hidden lg:block bg-[#f79324] hover:border hover:text-white text-black py-4 px-8 text-[16px] sm:text-[17px] md:text-[18px] uppercase skew-x-[-20deg] text-center'><span className='skew-x-[20deg] inline-block '>Get a Quote</span></span>
                        <span className='cursor-pointer inline-block  lg:hidden' onClick={openNavBat} ><GiHamburgerMenu className='ml-4 text-md inline-block text-white text-[22px]' /></span>
                    </div>
                </div>
            </div>
            <div className={`fixed bg-white h-full w-[60%] top-0 left-0 z-50 transition-all ease-linear ${openNav ? '' : '-translate-x-[110%]'}`}>
                <div className='px-4 py-3 bg-[#e8f8f9]'>
                    <img src={logo} alt="logo" className='w-[250px] mx-auto px-5' />
                </div>
                <div className='py-6 px-4'>
                    <NavList ul_class="font-semibold text-[14px] flex flex-col gap-x-6 ml-6 uppercase" li_class="pt-3 border-b-2 pb-3" close_click={closeNavBar} />
                    <span onClick={closeNavBar} className='absolute top-4 -right-3 cursor-pointer'><AiFillCloseCircle className='text-[25px] text-[#e30d16]' /></span>
                </div>
            </div>

            <EnqiuryPopup isOpen={modalIsOpen} onRequestClose={closeModal} />
        </div>
    )
}

export default Header