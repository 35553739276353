import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PartsComponents from './PartsComponents';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import part_bg from '../assets/images/sparebg.png';
import { useState } from 'react';
import EnqiuryPopup from './EnqiuryPopup';
import engine from '../assets/images/engine-room.png'
import deck from '../assets/images/deck-room.png'
import other from '../assets/images/Other.1.png'
const Parts = () => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
    document.body.classList.add('enquiry_body');
  };

  const closeModal = () => {
    document.body.classList.remove('enquiry_body');
    setModalIsOpen(false);
  };

  // const carouselSettings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   // autoplay: true,
  //   // autoplaySpeed: 2500 ,
  //   prevArrow: <CustomPrevArrow />, // Custom component for previous arrow
  //   nextArrow: <CustomNextArrow />,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2
  //       }
  //     },
  //     {
  //       breakpoint: 770,
  //       settings: {
  //         slidesToShow: 1
  //       }
  //     }
  //   ]
  // };

  return (
    <div className='bg-gray-300' style={{ background: `url(${part_bg})`, backgroundSize: "cover", backgroundAttachment: "fixed" }} >
      <div className='container mx-auto px-5 py-10 md:py-20'>
        <h3 className='text-white font-semibold text-center common_section_title' data-aos="fade-up">
          "Discover the Perfect Fit for Your Vessel"S

          </h3>
        <h3 className='text-white mt-3 font-bold  text-center w-full md:w-1/2 mx-auto' data-aos="fade-up">
        "Explore a world of customized solutions at Dockside Marine, where we help you find the perfect fit for your vessel's unique requirements.
           </h3>
        <div className='py-3 text-center'>
          <span onClick={openModal}  className='bg-[#0c3646] cursor-pointer mt-6 text-white py-4 px-8 text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase skew-x-[-20deg] book_taxi'><span className='skew-x-[20deg] inline-block '>Get a Quote</span></span>
        </div>
        {/* <div className='container mx-auto px-5 mt-10'> */}
          <div className="flex gap-x-4 mt-4 justify-center">
            <PartsComponents img={engine} alt="engine" name="Engine Room" link="marine-spares" />
            <PartsComponents img={deck} alt="deck" name="Deck Spares" link="deck-spares" />
            <PartsComponents img={other} alt="other" name="Other  Spares" link="other-spares" />
            </div>
        {/* </div> */}
      </div>
      <EnqiuryPopup isOpen={modalIsOpen} onRequestClose={closeModal} />
    </div >
  )
}

// Custom component for previous arrow
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className='text-3xl font-semibold absolute top-1/2 left-[-11%] sm:left-[-6%] md:left-0 -translate-y-1/2 cursor-pointer z-10'>
      <IoIosArrowBack />
    </div>
  );
};

// Custom component for next arrow
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="text-3xl font-semibold absolute top-1/2 right-[-11%] sm:right-[-6%] md:right-0 -translate-y-1/2 cursor-pointer z-10" onClick={onClick}>
      <IoIosArrowForward />
    </div>
  );
};
export default Parts