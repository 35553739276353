import { createSlice } from "@reduxjs/toolkit";
const GetBlogBySlugSlice = createSlice({

    name: "BlogbySlug",
    initialState: {
        loading: false,
        product: [],
        blog_category: [],
        categories: [],
        recent_records: [],
        time: [],
    },
    reducers: {
        GetBlogBySlugRequest(state, action) {
            return {
                loading: true,
            }
        },
        GetBlogBySlugSuccess(state, action) {
            return {
                loading: false,
                blog: action.payload.blog,
                blog_category: action.payload.blog_category,
                categories: action.payload.categories,
                recent_records: action.payload.recent_records,
                time: action.payload.time,
            }
        },
        GetBlogBySlugFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    },
});

const { actions, reducer } = GetBlogBySlugSlice;

export const { GetBlogBySlugRequest, GetBlogBySlugSuccess, GetBlogBySlugFail } = actions;

export default reducer;