import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import Header from '../../Header'
import { FaRegUserCircle } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBlogBySlug, getBlogCategory } from '../../../actions/blogCategoryActions';
import Loader from '../../Loader';
import { backendImageUrl } from '../../../config/config';
import Footer from '../../Footer';
import Wave from '../../Wave';
import { SlCalender } from 'react-icons/sl';
const BlogPage = () => {

    const { loading, blog, blog_category, categories, recent_records, time } = useSelector((state) => state.getBlogByslugState);
    const dispatch = useDispatch();
    const { slug } = useParams();

    useEffect(() => {
        dispatch(getBlogBySlug(slug));

    }, [])

    const BlogCategory = () => {
        dispatch(getBlogCategory);
    };
    const getBlog = (para_slug) => {
        dispatch(getBlogBySlug(para_slug));
    };

    return (
        <Fragment>
            <Header />
            {
                !loading ?
                    <div className='mt-6 pt-6 container mx-auto blogs_parent px-2 sm:px-7 md:px-8'>
                        <div className='flex flex-wrap md:flex-wrap lg:flex-nowrap    gap-3 sm:gap-5'>
                            {
                                blog &&
                                <div className='border w-full sm:w-full md:w-full lg:w-[70%] bg-white shadow-lg py-4 md:py-6 lg:py-8 px-6 md:px-8 lg:px-10 rounded-md'>
                                    <div><Link onClick={() => BlogCategory()} to="/blogs" className=' cursor-pointer uppercase inline-block bg-[#f79324] px-2 py-1  hover:text-white hover:bg-[#0c3646] transition-all rounded-xl text-xs font-medium'>{blog_category?.name}</Link>
                                    </div>
                                    <h3 className='pt-4 text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold '>{blog?.title}
                                    </h3>
                                    <div className=' pt-5 flex items-center gap-x-2 opacity-70'>
                                        <span><FaRegUserCircle className='text-lg md:text-xl' /></span>
                                        <span className=' text-[14px] lowercase'>{blog?.author}</span>
                                        <span className=' pl-3'><SlCalender /></span>
                                        <span>{time}</span>
                                    </div>
                                    <div className=' mt-6' dangerouslySetInnerHTML={{ __html: blog?.description }}>

                                    </div>
                                </div>
                            }


                            <div className='w-full sm:w-full md:w-full lg:w-[30%]'>
                                {
                                    categories && categories.length > 0 &&
                                    <div className='border bg-white shadow-lg p-3 sm:p-5 md:p-5 lg:p-6'>
                                        <h4 className=' text-lg sm:text-xl font-medium'>Topics</h4>
                                        <div className=' pt-4 sm:pt-5 md:pt-6 blog_topics_par'>
                                            {
                                                categories.map((category, index) => (
                                                    <Link key={category.id} to="/blogs" onClick={() => BlogCategory()} className='blog_topics flex gap-x-4 justify-between group pb-4'>
                                                        <span className='opacity-60 font-medium hover:pl-2 transition-all group-hover:pl-3 group-hover:text-[#f79324] group-hover:opacity-100'>{category.name}</span>
                                                        <span className='bg-[#f79324] rounded-full w-6 h-6 text-center flex justify-center'><span>{category?.blogs.length}</span></span>
                                                    </Link>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }

                                {
                                    recent_records && recent_records.length > 0 &&
                                    <div className='mt-6 border bg-white shadow-lg p-3 sm:p-5 md:p-5 lg:p-6'>
                                        <h4 className=' text-lg sm:text-xl font-medium'>Recent posts</h4>
                                        <div className=' pt-1 sm:pt-2 md:pt-2 blog_topics_par'>
                                            {
                                                recent_records.map((recent_record, index) => (
                                                    <Link key={recent_record.id} onClick={() => getBlog(recent_record?.slug)} to={`/blogs/${recent_record?.slug}`} className='flex group mt-5 gap-x-3 '>
                                                        <div className=' w-[30%]'>
                                                            <img src={backendImageUrl + recent_record.featured_image} alt="recent_post" className=' mx-auto w-[200px] h-[70px] object-cover' />
                                                        </div>
                                                        <div className=' w-[70%] font-medium opacity-70 group-hover:opacity-100 group-hover:text-[#f79324] transition-all'>
                                                            {recent_record.title}
                                                        </div>
                                                    </Link>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div> : <Loader />
            }
            <Footer />
            <Wave />
        </Fragment>
    )
}

export default BlogPage