import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PartnersComponents from './PartnersComponents';

import img1 from '../assets/images/partners/1.png';
import img2 from '../assets/images/partners/2.png';
import img3 from '../assets/images/partners/3.png';
import img4 from '../assets/images/partners/4.png';
// import img5 from '../assets/images/partners/5.png';
import img6 from '../assets/images/partners/6.png';
import img7 from '../assets/images/partners/7.png';
import img8 from '../assets/images/partners/8.png';
import img9 from '../assets/images/partners/9.png';
import img10 from '../assets/images/partners/10.png';
import img11 from '../assets/images/partners/11.png';
import img12 from '../assets/images/partners/12.png';
import img13 from '../assets/images/partners/13.png';
import img14 from '../assets/images/partners/14.png';
import img15 from '../assets/images/partners/15.png';
import img16 from '../assets/images/partners/16.png';
import img17 from '../assets/images/partners/17.png';
import img18 from '../assets/images/partners/18.png';
import img19 from '../assets/images/partners/19.png';
import img20 from '../assets/images/partners/20.png';
import img21 from '../assets/images/partners/21.png';
import img22 from '../assets/images/partners/22.png';
import img23 from '../assets/images/partners/23.png';
import img24 from '../assets/images/partners/24.png';
import img25 from '../assets/images/partners/25.png';
import img26 from '../assets/images/partners/26.png';
// import img27 from '../assets/images/partners/27.png';
import img28 from '../assets/images/partners/28.png';
import img29 from '../assets/images/partners/29.png';
import img30 from '../assets/images/partners/30.png';


const Partners = () => {

    const carouselSettings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToShow: 5,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2800 ,
        prevArrow: <></>,
        nextArrow: <></>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    return (
        <div className='container mx-auto pb-5 md:pb-10 px-5'>
            <h3 className='font-semibold text-center  common_section_title uppercase' data-aos="fade-up">OUR TRUSTED PARTNERS</h3>
            <h3 className='text-[#000] mt-3 font-bold text-center text-xl' data-aos="fade-up">We deal with major ship owners.</h3>
            <div className='container mx-auto px-5 mt-10'>
                <Slider {...carouselSettings}>
                    <PartnersComponents img_link={img1} />
                    <PartnersComponents img_link={img2} />
                    <PartnersComponents img_link={img3} />
                    <PartnersComponents img_link={img4} />
                    {/* <PartnersComponents img_link={img5} /> */}
                    <PartnersComponents img_link={img6} />
                    <PartnersComponents img_link={img7} />
                    <PartnersComponents img_link={img8} />
                    <PartnersComponents img_link={img9} />
                    <PartnersComponents img_link={img10} />
                    <PartnersComponents img_link={img11} />
                    <PartnersComponents img_link={img12} />
                    <PartnersComponents img_link={img13} />
                    <PartnersComponents img_link={img14} />
                    <PartnersComponents img_link={img15} />
                    <PartnersComponents img_link={img16} />
                    <PartnersComponents img_link={img17} />
                    <PartnersComponents img_link={img18} />
                    <PartnersComponents img_link={img19} />
                    <PartnersComponents img_link={img20} />
                    <PartnersComponents img_link={img21} />
                    <PartnersComponents img_link={img22} />
                    <PartnersComponents img_link={img23} />
                    <PartnersComponents img_link={img24} />
                    <PartnersComponents img_link={img25} />
                    <PartnersComponents img_link={img26} />
                    {/* <PartnersComponents img_link={img27} /> */}
                    <PartnersComponents img_link={img28} />
                    <PartnersComponents img_link={img29} />
                    <PartnersComponents img_link={img30} />
                </Slider>
            </div>
        </div>
    )
}

export default Partners