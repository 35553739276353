import React from 'react'
import { FaRegUserCircle } from 'react-icons/fa';
import { AiFillPlayCircle } from 'react-icons/ai';
import { backendImageUrl } from '../../config/config';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';


const BlogCategory = ({ blog, categoryName, blogClass }) => {
    const [LimitedText, setLimitedText] = useState("")
    useEffect(() => {
        if (blog) {
            // Example HTML content stored in a variable
            const htmlContent = blog.description;

            // Create a temporary element to parse the HTML content
            const tempElement = document.createElement('div');
            tempElement.innerHTML = htmlContent;

            // Extract text content using textContent or innerText
            const textContent = tempElement.textContent || tempElement.innerText;
            setLimitedText(textContent);
        }
    }, [blog])
    function TruncatedText({ text, maxLength }) {
        if (text.length <= maxLength) {
            return <span>{text}</span>;
        }

        const truncatedText = text.slice(0, maxLength) + '...';

        return <span>{truncatedText}</span>;
    }
    return (
        <div className={`grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 items-center gap-x-4 bg-white border rounded-md py-2 md:py-4 ${blogClass ? "ml-10 mr-10 sm:ml-8 md:ml-6 sm:mr-8 md:mr-6 border-2" : "shadow-lg shadow-slate-500"}`}>
            <div className=' cursor-pointer group overflow-hidden'>
                <Link to={`/blogs/${blog?.slug}`}><img src={backendImageUrl + blog?.featured_image} alt="blog_images" className=' group-hover:scale-105 transition-all w-[100%] sm:w-[50%] md:w-[80%] h-[200px] sm:h-[200px] md:h-[300px] object-contain mx-auto' /></Link>
            </div>
            <div className='p-5 sm:p-4 lg:p-4'>
                <div className=' flex flex-col gap-y-2 md:gap-y-3'>
                    <div className='flex gap-x-2 md:gap-x-3'>
                        <AiFillPlayCircle size={24} />
                        <span className=' cursor-pointer uppercase inline-block bg-[#f79324] px-2 py-1  hover:text-white hover:bg-[#0c3646] transition-all rounded-xl text-xs font-medium'>{categoryName}</span>
                    </div>
                    <h3 className='text-lg md:text-xl font-semibold cursor-pointer hover:text-[#0c3646] transition-all'><Link to={`/blogs/${blog?.slug}`}>{blog?.title}</Link></h3>

                    <p className=' text-xs md:text-base opacity-70'>
                        <TruncatedText text={LimitedText} maxLength={90} />

                    </p>
                    <div className=' flex gap-x-2 md:gap-x-3 items-center'>
                        <div className='flex gap-x-2 opacity-70 text-center hover:opacity-100 transition-all'>
                            <span><FaRegUserCircle className='text-lg md:text-xl' /></span>
                            <span className=' text-[14px] lowercase'>{blog?.author}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogCategory