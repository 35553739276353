import React from 'react'
import { Link } from 'react-router-dom';


const BannerComponents = ({ img_link, title, desc }) => {
    return (
        <div className='relative h-[80%] '>
            <img src={img_link} alt="banner" className='mx-auto w-full object-contain h-full'  />
            <div className='pt-5 absolute top-[430px] left-[430px] -translate-x-1/2 -translate-y-1/2 z-10'>
                <div className='text-center'>
                    <Link to="/about-us" className='cursor-pointer  md:inline-block bg-[#0c3646] mt-2 sm:mt-3 md:mt-4 text-white py-2 sm:py-3 md:py-4 px-4 sm:px-6 md:px-8 text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase skew-x-[-20deg] book_taxi'><span className='skew-x-[20deg] inline-block font-semibold'>Read More</span></Link>
                </div>
            </div>
        </div>)
}

export default BannerComponents