import React from 'react'
import { useEffect } from 'react';

const ProductDescription = ({ description }) => {
    // const words = description.split('<br>');

    // useEffect(() => {
        let words = description?.split('<br>');
    // }, [])


    return (
        <>
            {
                words && words.map((word, index) => (
                    <span key={index} className=' uppercase text-base sm:text-lg opacity-50 font-semibold'>{word.trim()}</span>
                ))
            }
        </>
    )
}

export default ProductDescription