import React from 'react'
import banner from '../assets/images/aboutusbanner.png';
import {IoIosArrowDown} from 'react-icons/io';
const AboutBanner = () => {
  return (
    <div className='mt-6'>
        <div className='h-[150px] md:h-[150px] lg:h-[200px] relative abt_banner'  data-aos="fade-down">
            <img src={banner} alt="banner" className=' absolute h-full w-full object-cover'/>
            <div className=' absolute py-5 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-10 text-2xl md:text-3xl lg:text-3xl font-semibold'>COMPANY
            <div className=' text-center pt-2'><IoIosArrowDown size={35} className=' font-bold mx-auto '/></div></div>
        </div>
    </div>
  )
}

export default AboutBanner