import React, { useEffect } from 'react'
import { Fragment } from 'react'
import Header from '../Header'
import { useParams } from 'react-router-dom';
import CategoryProducts from './CategoryProducts';
import Loader from '../Loader';
import Footer from '../Footer';
import Wave from '../Wave';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsByCategorySlug } from '../../actions/productActions';
import { useState } from 'react';
const Category = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchValid, setSearchValid] = useState(false);

    const { slug } = useParams();
    const dispatch = useDispatch();
    const { products } = useSelector((state) => state.productsByCategorySlugState);

    useEffect(() => {
        dispatch(getProductsByCategorySlug(slug));
    }, [])

    const handleSearchInputChange = (e) => {
        const query = e.target.value.trim(); // Trim the query
        setSearchQuery(query);

        if (query === '') {
            // Query is empty, reset the search results or display a message
            setSearchResults([]);
            setSearchValid(false);
        } else {
            // You can implement the actual search logic here.
            // For this example, let's filter a static array of products.

            // Replace this with your data or API call.
            const search_products = products;


            const filteredProducts = search_products.filter((product) =>
                product.name.toLowerCase().includes(query.toLowerCase())
            );
            setSearchValid(true);

            setSearchResults(filteredProducts);
        }


    };
    return (
        <Fragment>
            <Header />
            <div  data-aos="fade-right" className='bg-[#f79324] mt-6 py-4 px-3 md:px-5 uppercase text-black font-semibold text-center text-base sm:text-lg md:text-xl'>
                SCOPE OF SUPPLY {slug === "marine-spares" ? "Marine" : "Deck"} PRODUCTS
            </div>
            <div className='container mx-auto px-5 py-10 md:py-20' >
                <div className='flex items-center gap-4 md:gap-8 flex-wrap sm:flex-nowrap' data-aos="fade-right">
                    <div className='text-base md:text-lg bg-[#f1f1f1] text-center font-semibold uppercase px-3 py-3 w-full sm:w-[40%] md:w-[30%] lg:w-[30%]'>
                        ALL {slug === "marine-spares" ? "Marine" : "Deck"} SPARES
                    </div>
                    <div className='w-full sm:w-[60%] md:w-[70%] lg:w-[70%]'>
                        <input
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                            type="text" placeholder='SEARCH / BRAND / PRODUCT' className='w-full border border-[#878787] px-6 py-3' />
                    </div>
                </div>
                {
                    !searchValid &&
                    <>
                        {
                            products ?
                                <div className=' mt-10'>
                                    {
                                        products.length > 0 ?
                                            <div data-aos="fade-right" className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-8 md:flex-nowrap'>
                                                {

                                                    products.map((product, index) => (
                                                        <CategoryProducts key={product.id} name={product.name} image_link={product.image} slug={product.slug} />
                                                    ))

                                                }
                                            </div>
                                            :
                                            <>
                                                <h4 className=' text-2xl font-medium text-center'>Products are Not Available</h4>
                                            </>
                                    }

                                </div> : <Loader />
                        }
                    </>
                }
                {
                    searchValid && searchResults &&
                    <div className=' mt-10'>
                        {
                            searchResults.length > 0 ?
                                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-8 md:flex-nowrap'>
                                    {

                                        searchResults.map((product, index) => (
                                            <CategoryProducts key={product.id} name={product.name} image_link={product.image} slug={product.slug} />
                                        ))

                                    }
                                </div>
                                :
                                <>
                                    <h4 className=' text-2xl font-medium text-center'>Products are Not Available</h4>
                                </>
                        }

                    </div>
                }

            </div>

            <Footer />
            <Wave />
        </Fragment>
    )
}

export default Category