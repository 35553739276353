import axios from "axios";
import { GetBlogCategoryRequest, GetBlogCategorySuccess, GetBlogCategoryFail } from "../slices/GetBlogCategory";
import { GetBlogBySlugRequest , GetBlogBySlugSuccess , GetBlogBySlugFail } from "../slices/GetBlogBySlug";
import { backendUrl } from "../config/config";

export const getBlogCategory = async (dispatch) => {
    try {
       
        dispatch(GetBlogCategoryRequest())
        const { data } = await axios.get(backendUrl + `get_category`)
        dispatch(GetBlogCategorySuccess(data))
    } catch (error) {
        dispatch(GetBlogCategoryFail(error.response.data.message));
    }
}


export const getBlogBySlug = slug => async (dispatch) => {
    try {
        dispatch(GetBlogBySlugRequest())
        const { data } = await axios.post(backendUrl + `get_blog_by_slug/${slug}`)
        dispatch(GetBlogBySlugSuccess(data))
    } catch (error) {
        dispatch(GetBlogBySlugFail(error.response.data.message));
    }
}