import { createSlice } from "@reduxjs/toolkit";
const productsByCategorySlugSlice = createSlice({

    name: "productsbycategory",
    initialState: {
        loading: false,
        product: [],
    },
    reducers: {
        productByCategorySlugRequest(state, action) {
            return {
                loading: true,
            }
        },
        productByCategorySlugSuccess(state, action) {
            return {
                loading: false,
                products: action.payload.products,
            }
        },
        productByCategorySlugFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    },
});

const { actions, reducer } = productsByCategorySlugSlice;

export const { productByCategorySlugRequest, productByCategorySlugSuccess, productByCategorySlugFail } = actions;

export default reducer;