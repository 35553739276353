import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from '../assets/images/banner1.jpg';
import img2 from '../assets/images/banner2.jpg';
import CustomNextArrow from './arrows/CustomNextArrow';
import CustomPrevArrow from './arrows/CustomPrevArrow';
import BannerComponents from './BannerComponents';

const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500 ,
    prevArrow: <CustomPrevArrow />, // Custom component for previous arrow
    nextArrow: <CustomNextArrow />,
    // responsive: [
    //     {
    //         breakpoint: 1024,
    //         settings: {
    //             slidesToShow: 2
    //         }
    //     },
    //     {
    //         breakpoint: 770,
    //         settings: {
    //             slidesToShow: 1
    //         }
    //     }
    // ]
};

const Banner = () => {
    return (
        <div className='bg-gray-300 banner_top'>
            <Slider {...carouselSettings}>
                <BannerComponents img_link={img1} title="DOCKSIDE MARINE collaborates with Verve Technology Services Pte Ltd," desc ="to reverse engineer obsolete shipping spares" />
                <BannerComponents img_link={img2} title="Explore. Faster. Envision. Smarter." desc ="Ethical Procurment is the way of life at DOCKSIDE MARINE" />

                {/* <div className='relative h-[80%] banner_parent'>
                    <img src={img1} alt="banner" className='mx-auto w-full object-contain h-full' loading="lazy" />
                    <div className='pt-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10'>
                        <h3 className='text-center text-white text-base sm:text-xl md:text-[40px] w-[80%] md:w-[90%]  lg:w-[60%] banner_heading mx-auto font-semibold'>DOCKSIDE MARINE collaborates with Verve Technology Services Pte Ltd,</h3>
                        <p className='text-sm text-white md:text-xl text-center pt-2 md:pt-5'>to reverse engineer obsolete shipping spares</p>
                        <div className='text-center'>
                            <Link to="/about-us" className='cursor-pointer  md:inline-block bg-[#0c3646] mt-2 sm:mt-3 md:mt-4 text-white py-2 sm:py-3 md:py-4 px-4 sm:px-6 md:px-8 text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase skew-x-[-20deg] book_taxi'><span className='skew-x-[20deg] inline-block font-semibold'>Read More</span></Link>
                        </div>
                    </div>
                </div>
                <div className='relative h-[80%] banner_parent'>
                    <img src={img2} alt="banner" className='mx-auto w-full object-contain h-full' loading="lazy" />
                    <div className='pt-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10'>
                        <h3 className='text-center text-white text-base sm:text-xl md:text-[40px] w-[80%] md:w-[90%]  lg:w-[60%] banner_heading mx-auto font-semibold'>Explore. Faster. Envision. Smarter.</h3>
                        <p className='text-sm text-white md:text-xl text-center pt-3 md:pt-5'>Ethical Procurment is the way of life at DOCKSIDE MARINE</p>
                        <div className='text-center'>
                            <Link to="/about-us" className='cursor-pointer  md:inline-block bg-[#0c3646] mt-2 sm:mt-3 md:mt-4 text-white py-2 sm:py-3 md:py-4 px-4 sm:px-6 md:px-8 text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase skew-x-[-20deg] book_taxi'><span className='skew-x-[20deg] inline-block font-semibold'>Read More</span></Link>
                        </div>
                    </div>
                </div> */}
            </Slider>
        </div>
    )
}

// // Custom component for previous arrow
// const CustomPrevArrow = (props) => {
//     const { onClick } = props;
//     return (
//         <div onClick={onClick} className='text-3xl font-semibold absolute top-1/2 left-[-11%] sm:left-[-6%] md:left-0 -translate-y-1/2 cursor-pointer z-10'>
//             <IoIosArrowBack />
//         </div>
//     );
// };

// // Custom component for next arrow
// const CustomNextArrow = (props) => {
//     const { onClick } = props;
//     return (
//         <div className="text-3xl font-semibold absolute top-1/2 right-[-11%] sm:right-[-6%] md:right-0 -translate-y-1/2 cursor-pointer z-10" onClick={onClick}>
//             <IoIosArrowForward />
//         </div>
//     );
// };

export default Banner