import React from 'react'
import logo from '../assets/images/marine_logo.jpg';

const Footer = () => {
    return (

        <div className='container mx-auto py-5 md:py-10 px-5'>
            <div data-aos="fade-up" className='flex flex-wrap md:flex-nowrap'>
                <div className='w-full sm:w-full md:w-1/4 bg-white border rounded-lg' data-aos="fade-up">
                    <img src={logo} alt="logo_image" className='w-[150px] h-[100px] md:w-[200px] md:h-[150px] object-contain mx-auto' />
                </div>
                <div data-aos="fade-up" className='w-full sm:w-full md:3/4 bg-[#0c3646] text-center sm:text-center md:text-left py-2 px-3 sm:px-4 sm:py-3 md:py-4 md:px-6 text-white font-medium rounded-e-lg'>
                    <div className='' data-aos="fade-up">DOCKSIDE MARINE MEASURING & CONTROL SYSTEM TRADING LLC.
                                               <br/>        PO BOX: 239867 Office No. 503 5th Floor Al Saud Building, 
                                               <br/>      Al Qusais Industrial Area 4 Dubai, UAE. TRN: 100492705700003
</div>
                    <div className='pt-1' data-aos="fade-up">+971 42723260</div>
                    <div className='pt-1' data-aos="fade-up">info@docksidemarines.com</div>
                    <div className='pt-6' data-aos="fade-up">DOCKSIDE MARINE @ 2023</div>
                </div>
            </div>
        </div>
    )
}

export default Footer