import React from 'react'

const PartnersComponents = ({img_link}) => {
  return (
    <div>
        <img src={img_link} alt="partners image" className='mx-auto' data-aos="fade-up" />
    </div>
  )
}

export default PartnersComponents