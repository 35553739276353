import React from 'react'
import { Fragment } from 'react'
import Header from './Header'
import ReachUs from './ReachUs'
import Partners from './Partners'
import Footer from './Footer'
import part_bg from '../assets/images/sparebg.png';
import Wave from './Wave'
import Deals from './Deals'

const ContactUs = () => {
    return (
        <Fragment>
            <Header />
            <div className='contact_par relative py-3 md:py-10 px-5' style={{ background: `url(${part_bg})`, backgroundSize: "cover",backgroundAttachment:"fixed" }} >
                <section className="text-gray-700 body-font relative container mx-auto z-10">
                    <div className="container pb-4 pt-10 mx-auto">
                        <div className="flex flex-col text-center w-full mb-1 md:mb-8 lg:mb-12">
                            <h1 className="uppercase common_section_title font-medium title-font mb-2 text-[#fff]" data-aos="fade-up">
                                Contact Us
                            </h1>
                        </div>
                        <div className="lg:w-[70%] md:w-[80%] w-full mx-auto">
                            <div className="flex flex-wrap -m-2">
                                <div className="p-2 w-full md:w-1/2">
                                    <div className="relative" data-aos="fade-up">
                                        <label htmlFor="name" className="leading-7 text-sm md:text-lg  lg:text-xl text-white">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            className="mt-1 w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    </div>
                                </div>
                                <div className="p-2 w-full md:w-1/2">
                                    <div className="relative" data-aos="fade-up">
                                        <label
                                            htmlFor="email"
                                            className="leading-7 text-sm md:text-lg  lg:text-xl text-white"
                                        >
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="mt-1 w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    </div>
                                </div>
                                <div className="p-2 w-full">
                                    <div className="relative" data-aos="fade-up">
                                        <label
                                            htmlFor="message"
                                            className="leading-7 text-sm md:text-lg  lg:text-xl text-white"
                                        >
                                            Message
                                        </label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            className="mt-1 w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 h-32 md:h-48 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="p-2 w-full">
                                    <button data-aos="fade-up" className="flex mx-auto hovertext-white font-medium bg-[#f79324] text-black border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Partners />
            <Deals />
            <Footer />
            <Wave/>
        </Fragment>
    )
}

export default ContactUs