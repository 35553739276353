import React from 'react'
import { FaRegComment } from 'react-icons/fa';
import { FiThumbsUp } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import section_bg from '../assets/images/section_bg.png';
const ReachUs = () => {
    const divStyle = {
        backgroundImage: `url(${section_bg})`,
        backgroundSize: 'cover', // You can adjust this property as needed
        backgroundPosition: '800px 53px',
        backgroundRepeat: 'no-repeat',
    };
    return (
        <div style={divStyle}>
            <div className='container mx-auto pt-none py-5 md:py-10 px-5 '>
                <h3 className=' font-semibold text-center common_section_title uppercase' data-aos="fade-up">Reach Us</h3>
                <h3 className='text-[#000] mt-3 font-bold text-center text-xl' data-aos="fade-up">Our experts have many decades of experience and proven competence in the Industry. </h3>
                <div className=' bg-[#f5f6fa] py-10 px-5 rounded-md min-h-[300px]'>
                    <div className='flex flex-col justify-between h-full '>
                        <div className=' flex flex-col gap-x-4 items-start text-center'>
                            <div className='flex justify-center items-center gap-x-5 leading-[1] w-[100%]' data-aos="fade-up" >
                                <div >
                                    <FaRegComment size={25} className=' text-[#007ac2]' />
                                </div>
                                <div>
                                    <div className='text-lg md:text-2xl font-medium leading-[1]' data-aos="fade-up">
                                        Sales Support
                                    </div>
                                </div>
                            </div>

                            <div className='pt-3 text-base md:text-lg' data-aos="fade-up">
                                Our world-class team has extensive experience in handling complete end-to-end solutions and niche services of any complexity, scope and nature in the marine industry.
                            </div>
                        </div>
                        <div className='text-center pt-4' data-aos="fade-up">
                            <Link to='/contact-us' className='bg-[#0c3646] cursor-pointer text-white py-4 px-8 text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase skew-x-[0] book_taxi'><span className='skew-x-[0] inline-block uppercase'>Click Here</span></Link>
                        </div>
                    </div>
                </div>
                {/* <div className=' bg-[#f5f6fa] py-7 px-5 rounded-md min-h-[300px]'>
                        <div className='flex flex-col justify-between h-full'>
                            <div className=' flex gap-x-4 items-start'>
                                <div>
                                    <FiThumbsUp size={25} className=' text-[#007ac2]' />
                                </div>
                                <div>
                                    <div className=' text-lg md:text-2xl  font-medium leading-[1]' data-aos="fade-up">
                                        Help and Support
                                    </div>
                                    <div className='pt-3 text-base md:text-lg' data-aos="fade-up">
                                        We wish to develop a long term business relationship with you with our best services and looking forward to hear from you.
                                    </div>
                                </div>
                            </div>
                            <div className='text-center pt-4' data-aos="fade-up">
                                <Link to='/contact-us' className='bg-[#0c3646] cursor-pointer text-white py-4 px-8 text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase skew-x-[0] book_taxi'><span className='skew-x-[0] inline-block uppercase'>Click Here</span></Link>
                            </div>
                        </div>
                    </div> */}
            </div>
        </div>

    )
}

export default ReachUs