import React, { Fragment, useEffect, useState } from 'react'
import About from './About';
import Banner from './Banner';
import Header from './Header';
import PhoneComponent from './PhoneComponent';
import Parts from './Parts';
import ReachUs from './ReachUs';
import ScrollUp from './ScrollUp';
import WhatsappComponent from './WhatsappComponent';
import { animateScroll as scroll } from 'react-scroll';
import Partners from './Partners';
import Footer from './Footer';
import Wave from './Wave';
import Deals from './Deals';

const Home = () => {
    const [changeLink, setChangeLink] = useState('home');
    // alert(changeLink);
    useEffect(() => {
        if (changeLink) {
            scroll.scrollToTop({ smooth: false });
        }
    }, [changeLink])

    return (
        <Fragment>
            <Header />
            <Banner />
            <About />
            <Parts />
            <ReachUs />
            <Partners />
            <Deals />
            <Footer />
            <WhatsappComponent />
            <PhoneComponent />
            <ScrollUp />
            <Wave/>
        </Fragment>
    )
}

export default Home