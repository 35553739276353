import React from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { getProductsByCategorySlug } from '../actions/productActions';
import { getBlogCategory } from '../actions/blogCategoryActions';

const NavList = ({ ul_class, li_class }) => {

    const dispatch = useDispatch();

    const handleTabLink = (slug) => {
        dispatch(getProductsByCategorySlug(slug));
    };
    const BlogCategory = () => {
        dispatch(getBlogCategory);
    };
    return (
        <ul className={`${ul_class}`}>
            <li className={`${li_class}`}> <Link className='block relative z-10 sep_navlilnk transition-all ease-linear px-3 py-2 md:px-5 hover:text-white' to="/"><span className=' relative z-10'>Home</span></Link></li>
            <li className={`${li_class}`}> <Link className='block relative z-10 sep_navlilnk transition-all ease-linear px-3 py-2 md:px-5 hover:text-white' to="/about-us"><span className=' relative z-10'>About us</span></Link></li>
            <li className={`${li_class}`}> <Link className='block relative z-10 sep_navlilnk transition-all ease-linear px-3 py-2 md:px-5 hover:text-white' onClick={() => handleTabLink('marine-spares')} to="/category/marine-spares"><span className=' relative z-10'>Engine Room Spares</span></Link></li>
            <li className={`${li_class}`}> <Link className='block relative z-10 sep_navlilnk transition-all ease-linear px-3 py-2 md:px-5 hover:text-white' onClick={() => handleTabLink('deck-spares')} to="/category/deck-spares"><span className=' relative z-10'>Deck Spares</span></Link></li>
            <li className={`${li_class}`}> <Link className='block relative z-10 sep_navlilnk transition-all ease-linear px-3 py-2 md:px-5 hover:text-white' onClick={() => handleTabLink('deck-spares')} to="/category/other-spares"><span className=' relative z-10'>Others Spares</span></Link></li>
            <li className={`${li_class}`}> <Link className='block relative z-10 sep_navlilnk transition-all ease-linear px-3 py-2 md:px-5 hover:text-white' to="/contact-us"><span className=' relative z-10'>Contact Us</span></Link></li>
            <li className={`${li_class}`}> <Link className='block relative z-10 sep_navlilnk transition-all ease-linear px-3 py-2 md:px-5 hover:text-white' to="/blogs" onClick={() => BlogCategory()}><span className=' relative z-10'>Blogs</span></Link></li>
        </ul>
    )
}

export default NavList