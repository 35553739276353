
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from "./components/Home";
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Category from './components/category/Category';
import Product from './components/product/Product';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the CSS for AOS
import Blogs from './components/Blogs/Blogs';
import BlogPage from './components/Blogs/BlogPage/BlogPage';

function App() {


  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <ToastContainer />
      <BrowserRouter >
        <Routes>
          <Route path="/" element={< Home />}>
          </Route>
          <Route path="/about-us" element={< AboutUs />}>
          </Route>
          <Route path="/contact-us" element={< ContactUs />}>
          </Route>
          <Route path="/category/:slug" element={< Category />}>
          </Route>
          <Route path="/product/:slug" element={< Product />}>
          </Route>
          <Route path="/blogs" element={< Blogs />}>
          </Route>
          <Route path="/blogs/:slug" element={< BlogPage />}>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
