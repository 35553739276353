import React from 'react'
import { Fragment } from 'react'
import Header from './Header'
import AboutBanner from './AboutBanner'
import img_gallery from "../assets/images/about-grid.png";
import ReachUs from './ReachUs';
import Partners from './Partners';
import Footer from './Footer';
import Wave from './Wave';
import section_bg from '../assets/images/section_bg.png';
import Deals from './Deals';
const AboutUs = () => {
    const divStyle = {
        backgroundImage: `url(${section_bg})`,
        backgroundSize: 'cover', // You can adjust this property as needed
        backgroundPosition: '800px 53px',
        backgroundRepeat:'no-repeat',
    };
    return (
        <Fragment>
            <Header />
            <AboutBanner />
            <div className='container mx-auto py-4 pb-6 md:py-16 px-5'  style={divStyle}>
                <div className=' flex items-center gap-y-4  md:gap-y-0  gap-x-0 sm:gap-x-0 md:gap-x-20 flex-wrap md:flex-nowrap'>
                    <div className='sm:w-[100%]  md:w-[50%]'>
                        {/* <h5 className='text-[#0c3646] font-semibold'  data-aos="fade-down">DOCKSIDE MARINE PROFILE</h5> */}
                        <h3 className=' mt-4 font-bold common_section_title w-full sm:w-[90%] md:w-[80%] lg:w-[90%] leading-[60px]'  data-aos="fade-down">
                        Dockside Marine: Navigating Excellence in Ship Spares
</h3>
                        <p className='pt-3 text-lg pl-0 sm:pl-2 md:pl-4 lg:pl-6'  data-aos="fade-down">
                        Dockside Marine is a distinguished provider of ship engine and deck spares, committed to ensuring the smooth operation and longevity of vessels worldwide.
                            </p>
                        <p className='pt-2 text-lg pl-0 sm:pl-2 md:pl-4 lg:pl-6'  data-aos="fade-down">
                        With a focus on delivering high-quality solutions, we have earned a reputation as a trusted partner in the maritime industry.

</p>
                    </div>
                    <div className='w-full md:w-[50%]'>
                        <img src={img_gallery} alt="sction_image"  data-aos="fade-down"/>
                    </div>
                </div>
            </div>
            <div className='py-3 md:py-8 text-center px-5'>
                <div className='text-[#0c3646] font-semibold'  data-aos="fade-down">IDENTIFYING, APPROACHING, AND RECOGNIZING </div>
                <div className='pt-4 w-full sm:w-[80%] md:w-[70%] lg:w-[50%] mx-auto text-lg md:text-xl lg:text-2xl opacity-80'  data-aos="fade-down">
                    We are a trusted name in the marine industry and deal in Japanese, Korean and European spare parts.  Our technically trained and competitive workforce strive to not only meet but exceed customers’ expectations. We are one of the most reliable suppliers, successfully serving our customers worldwide for years.
                </div>
            </div>
            <div className='container mx-auto  py-3 md:py-8 px-5'>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-5'>
                    <div className='flex bg-[#007ac2] flex-col justify-center items-center text-white gap-y-3 py-10'  data-aos="fade-down">
                        <span className=' text-6xl font-semibold'  data-aos="fade-down">100 <sub className=' text-3xl'  data-aos="fade-down">%</sub></span>
                        <span className=' text-lg'  data-aos="fade-down">Client Satisfaction</span>
                    </div>
                    <div>
                        <h4 className='text-2xl font-medium'  data-aos="fade-down">Mission</h4>
                        <p className='pt-3 font-semibold text-lg w-full sm:w-[80%] md:w-[70%]'  data-aos="fade-down">
                        Our mission is to be the premier global source for ship spares, supporting the maritime community with reliable, top-tier products and services.</p>
                        <p className='pt-1 font-semibold text-lg w-full sm:w-[80%] md:w-[70%]'  data-aos="fade-down">
                        We aim to enhance the efficiency, safety, and sustainability of maritime operations through our offerings.Dockside Marine is more than a supplier; we are your partner in maritime success, committed to elevating the standards and expectations of ship engine and deck spares.
 </p>
                    </div>
                    <div>
                        <h4 className='text-2xl font-medium'  data-aos="fade-down">Vision</h4>
                        <p className='pt-3 font-semibold text-lg w-full sm:w-[80%] md:w-[70%]'  data-aos="fade-down">
                        Our vision is to drive innovation in the maritime industry by consistently setting new standards for quality and performance.
</p>
                        <p className='pt-1 font-semibold text-lg w-full sm:w-[80%] md:w-[70%]'  data-aos="fade-down">
                        We aspire to be the catalyst for positive change, promoting a more efficient and environmentally conscious maritime world through our exceptional products and services.Dockside Marine is more than a supplier; we are your partner in maritime success, committed to elevating the standards and expectations of ship engine and deck spares.

Product Catalogue                        </p>
                    </div>
                </div>
            </div>
            <div className='container mx-auto  py-3 md:py-8 px-5'  data-aos="fade-down">
                <div className='bg-[#007ac2] text-white font-semibold py-10 px-3'>
                    <div className='flex flex-col sm:flex-row justify-center gap-x-5 gap-y-4 items-center'>
                        <span className=' text-xl'>Product Catalogue</span>
                        <a href="#" className=' border-2 border-white py-3 px-8 hover:text-[#007ac2] hover:bg-white transition-all rounded-lg'>Download</a>
                    </div>
                </div>
            </div>
            <ReachUs />
            <Partners />
            <Deals />
            <Footer />
            <Wave />
        </Fragment>
    )
}

export default AboutUs