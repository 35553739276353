import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PartnersComponents from './PartnersComponents';
import img1 from '../assets/images/Deals/deal4.png';
import img2 from '../assets/images/Deals/deal5.png';
import img3 from '../assets/images/Deals/deal1.png';
import img4 from '../assets/images/Deals/deal2.png';
import img5 from '../assets/images/Deals/deal3.png';
import img6 from '../assets/images/Deals/deal6.png';
import img7 from '../assets/images/Deals/deal7.png';
import img8 from '../assets/images/Deals/deal8.png';

const Deals = () => {
    const carouselSettings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToShow: 5,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2800 ,
        prevArrow: <></>,
        nextArrow: <></>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

  return (
        <div className='container mx-auto py-5 md:py-10 px-5'>
            <h3 className='font-semibold text-center  common_section_title uppercase' data-aos="fade-up">BRAND WE DEALS WITH
</h3>
            <h3 className='text-[#000] mt-3 font-bold text-center text-xl' data-aos="fade-up">We deal with major ship owners.
</h3>
            <div className='container mx-auto px-5 mt-10'>
                <Slider {...carouselSettings}>
                    <PartnersComponents img_link={img1} />
                    <PartnersComponents img_link={img2} />
                    <PartnersComponents img_link={img3} />
                    <PartnersComponents img_link={img4} />
                    <PartnersComponents img_link={img5} />
                    <PartnersComponents img_link={img6} />
                    <PartnersComponents img_link={img7} />
                    <PartnersComponents img_link={img8} />
                </Slider>
            </div>
        </div>  )
}

export default Deals
