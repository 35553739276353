import React, { useEffect } from 'react'
import { Fragment } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import Wave from '../Wave'
import BlogCategory from './BlogCategory'
import { useDispatch, useSelector } from 'react-redux'
import { getBlogCategory } from '../../actions/blogCategoryActions'
import Loader from '../Loader'
import { IoIosArrowBack, IoIosArrowForward} from "react-icons/io";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Blogs = () => {

    const { categories, loading } = useSelector((state) => state.getBlogCategoryState);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBlogCategory);
    }, [])

    const carouselSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2500,
        prevArrow: <CustomPrevArrow />, // Custom component for previous arrow
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    return (
        <Fragment>
            <Header />
            {
                !loading && categories ?
                    categories.length == 0 ?
                        <h1 className='font-sembold text-lg md:text-xl'>No Categories</h1> :

                        categories.map((category, index) => (
                            <div key={category.id} className='mt-6 pt-6 container mx-auto blogs_parent px-2 sm:px-3 md:px-4'>

                                <h4 className=' text-lg md:text-xl lg:text-2xl font-medium'>Category - {category.name}</h4>
                                {
                                    category?.blogs &&
                                        category?.blogs.length <= 2 ?
                                        <div className='py-3 md:py-5'>
                                            <div className=' grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2  gap-x-3 md:gap-x-5 lg:gap-x-6 gap-y-2 md:gap-y-4 lg:gap-y-5 items-center'>
                                                {
                                                    category.blogs.map((blog, index) => (
                                                        <BlogCategory key={blog.id} blog={blog} categoryName={category?.name} />
                                                    ))
                                                }
                                            </div>
                                        </div> :
                                        <div className='py-3 md:py-5'>
                                            <div className=''>
                                                <Slider {...carouselSettings}>

                                                    {
                                                        category.blogs.map((blog, index) => (
                                                            <BlogCategory key={blog.id} blog={blog} categoryName={category?.name} blogClass={true}/>
                                                        ))
                                                    }
                                                </Slider>
                                            </div>
                                        </div>


                                }

                            </div>
                        ))
                    : <Loader />
            }
            <Footer />
            <Wave />
        </Fragment>
    )
}


// Custom component for previous arrow
const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div onClick={onClick} className='text-3xl font-semibold absolute top-1/2 left-0 sm:left-[-4%] md:-left-3 -translate-y-1/2 cursor-pointer z-10'>
        <IoIosArrowBack />
      </div>
    );
  };
  
  // Custom component for next arrow
  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="text-3xl font-semibold absolute top-1/2 right-0 sm:right-[-4%] md:-right-3 -translate-y-1/2 cursor-pointer z-10" onClick={onClick}>
        <IoIosArrowForward />
      </div>
    );
  };

export default Blogs