import React from 'react'
import { Link } from 'react-router-dom';
import product_img from '../../assets/images/product_images/1.png';
import { backendImageUrl } from '../../config/config';
const CategoryProducts = ({ name, image_link, slug }) => {
    return (
        <Link to={`/product/${slug}`} className='grid grid-cols-2 items-center w-full single_pro_parent'>
            <img src={backendImageUrl + image_link} alt="product image" className=' w-[150px] sm:w-[160px] md:w-[170px] lg:w-[180px] h-[150px] object-contain' />
            <div className=' text-base sm:text-lg md:text-xl font-semibold hover:text-[#0c3646] transition-all'>
                {name}
            </div>
        </Link>
    )
}

export default CategoryProducts