import React from 'react'
import about from "../assets/images/marine-about.jpg";

const About = () => {
    return (
        <>
            <div className='container mx-auto py-10 md:py-16 px-5' id='about'>
                <div className=' flex items-center gap-y-4  md:gap-y-0  gap-x-0 sm:gap-x-0 md:gap-x-36 flex-wrap md:flex-nowrap'>
                    <div className='sm:w-[100%]  md:w-[40%]'>
                        <img src={about} alt="about" data-aos="fade-up"/>
                    </div>
                    <div className='w-full md:w-[60%]'>
                        <h3 className=' leading-[57px] mt-4 w-full sm:w-[90%] md:w-[80%] lg:w-[60%] common_section_title' 
                        data-aos="fade-up">
                        Dockside 
                        <br/>Marine: 
                        <br/>Powering Your 
                        <br/>Maritime 
                        <br/>Success
</h3>
                    </div>
                </div>
            </div>
            <div className='container mx-auto py-0 pb-6 md:py-16 px-5' id='about'>
                <div className=' flex items-center gap-y-4  md:gap-y-0  gap-x-0 sm:gap-x-0 md:gap-x-20 flex-wrap md:flex-nowrap'>
                    <div className='sm:w-[100%]  md:w-[50%]'>
                        <h3 className='mt-4  common_section_title leading-[60px]' data-aos="fade-up">
                        Your Trusted Global Partner in Marine Spare Procurement and Distribution
                            </h3>
                    </div>
                    <div className='w-full md:w-[50%]'>
                        <p className='sm:mt-2 md:mt-4 w-full text-xl' data-aos="fade-up">
                        Dockside Marine specializes in the procurement, marketing, supply, and distribution of 
                        OEM marine spares sourced from prominent manufacturers in Japan, Korea, and around the world. 
                        Our unwavering commitment to technical excellence, innovation, effective leadership, and a 
                        dedicated workforce consistently exceeds customer expectations, enhancing the efficiency and 
                        performance of maritime assets.

</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About