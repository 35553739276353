import React, {useState } from 'react'
import { BsArrowUp } from 'react-icons/bs';
import { animateScroll as scroll } from 'react-scroll';

const ScrollUp = () => {

  const [showButton, setShowButton] = useState(false);

  // const handleScroll = () => {
  //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  //   const shouldShowButton = scrollTop > 230;
  //   setShowButton(shouldShowButton);
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const scrollToTop = () => {
    scroll.scrollToTop({ smooth: true });
  };
  return (
    <div className={`fixed bottom-[135px] right-[40px]  sm:right-[40px] md:right-[55px] z-10 scrolll_btn`} onClick={scrollToTop} >
      <a href="#" className='inline-block outline-4 outline-white outline  w-[35px] sm:w-[40px] md:w-[45px]  lg:w-[50px] h-[35px] sm:h-[40px] md:h-[45px] lg:h-[50px] bg-[#0c3646] hover:bg-[#f79324] transition-all ease-linear rounded-full relative group'> <BsArrowUp className='text-[16px] group-hover:text-black  w-[20px] h-[20px] text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' /></a>
    </div>
  )
}

export default ScrollUp