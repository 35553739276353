import { createSlice } from "@reduxjs/toolkit";
const GetBlogCategorySlice = createSlice({

    name: "BlogCategory",
    initialState: {
        loading: false,
        product: [],
    },
    reducers: {
        GetBlogCategoryRequest(state, action) {
            return {
                loading: true,
            }
        },
        GetBlogCategorySuccess(state, action) {
            return {
                loading: false,
                categories: action.payload.categories,
            }
        },
        GetBlogCategoryFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    },
});

const { actions, reducer } = GetBlogCategorySlice;

export const { GetBlogCategoryRequest, GetBlogCategorySuccess, GetBlogCategoryFail } = actions;

export default reducer;