import React from 'react'
import { PiCarSimple } from 'react-icons/pi';
import { BiAnchor } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getProductsByCategorySlug } from '../actions/productActions';
const  PartsComponents = ({img,imgname,name, link }) => {

    const dispatch = useDispatch();

    const handleTabLink = (slug) => {
        dispatch(getProductsByCategorySlug(slug));
    };

    return (
        <div className='bg-white py-4 px-4 md:px-10 mr-0 sm:mr-2 md:mr-3 lg:mr-3 w-[300px]'>
            <div className='pb-10 text-center'>
                <img src={img}  alt={imgname}/>
            </div>
            <div className='bg-[#0c3646] text-white  pt-3 pb-6 relative'>
                <h4 className='font-bold text-center pt-5 text-[20px] sm:text-[22px] md:text-[25px]'>{name}</h4>
                <div className='text-center mt-5 mx-10'>
                    <Link  to={`/category/${link}`} onClick={() => handleTabLink(link)}  className='inline-block border-white border text-white py-3 px-6 w-full text-[14px] font-semibold uppercase hover:bg-[#f79324] hover:text-black transition-all ease-linear'>Click Here</Link>
                </div>
            </div>
        </div>
    )
}

export default PartsComponents