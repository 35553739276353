import { createSlice } from "@reduxjs/toolkit";
const ProductBySlugSlice = createSlice({

    name: "ProductBySlug",
    initialState: {
        loading: false,
        product: [],
    },
    reducers: {
        ProductBySlugRequest(state, action) {
            return {
                loading: true,
            }
        },
        ProductBySlugSuccess(state, action) {
            return {
                loading: false,
                product: action.payload.product,
            }
        },
        ProductBySlugFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    },
});

const { actions, reducer } = ProductBySlugSlice;

export const { ProductBySlugRequest, ProductBySlugSuccess, ProductBySlugFail } = actions;

export default reducer;