import React from 'react'
import phone_img from "../assets/images/phone_img.png";
const PhoneComponent = () => {
    return (
        <div className=' fixed bottom-[55px] left-[40px] sm:left-[40px] md:left-[55px] z-10'>
            <a href="tel:+971 42723260"> <img src={phone_img} className='w-[40px] sm:w-[40px] md:w-[50px] lg:w-[60px]' alt="whatsapp_img" /></a>
        </div>
    )
}

export default PhoneComponent